export const EN_QUESTIONNAIRE_STRINGS = {
  QUESTIONNAIRE: '{count, plural, one {Questionnaire} other {Questionnaires}}',
  QUESTIONNAIRE_ENTITY_NAME: 'Questionnaire',
  QUESTIONNAIRE_METADATA_LABEL: 'Questionnaire',
  QUESTIONNAIRE_METADATA_TITLE_LABEL: 'Title',
  QUESTIONNAIRE_METADATA_TITLE_HINT: 'Displays the title of the Questionnaire',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_ID_LABEL: 'ID',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_DESCRIPTION_LABEL: 'Description',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_DESCRIPTION_HINT:
    'Displays the details about the questionnaire',
  QUESTIONS_LABEL: 'Questions',
  QUESTION_LABEL_ADD: 'Add Question',
  QUESTION_ITEM_LABEL: 'Question',
  QUESTION_TEXT_LABEL: 'Text',
  QUESTION_TEXT_HINT: 'Displays the text of the question',
  LINK_ID_LABEL: 'Link ID',
  QUESTION_CODE_LABEL: 'Question Code',
  QUESTION_CODE_HINT: 'Displays the question codes of the question',
  QUESTION_BINDING_LABEL: 'Question Binding',
  QUESTION_BINDING_HINT:
    'Normative if verbiage from standardized health survey is used. Preferred if verbiage has been deviated from a standardized health survey.',
  QUESTION_BINDING_NORMATIVE: 'normative',
  QUESTION_BINDING_PREFERRED: 'preferred',
  QUESTION_ANSWER_TYPE_LABEL: 'Answer Type',
  QUESTION_ANSWER_TYPE_HINT:
    'Displays type of expected answer (e.g., quantity, choice)',
  QUESTION_ANSWER_TYPE_QUANTITY: 'quantity',
  QUESTION_ANSWER_TYPE_CHOICE: 'choice',
  QUESTION_ANSWER_TYPE_TEXT: 'text',
  QUESTION_ANSWER_TYPE_DATE: 'date',
  QUESTION_ANSWER_TYPE_BOOLEAN: 'boolean',
  QUESTION_ANSWER_TYPE_SCALE: 'scale',
  QUESTION_ANSWER_TYPE_CODED_VALUE: 'coded value',
  QUESTION_ANSWER_TYPE_TIME: 'time',
  QUESTION_ANSWER_TYPE_CALCULATED: 'calculated',
  QUESTION_INPUT_METHOD: 'Input Method',
  QUESTION_INPUT_METHOD_HINT: 'Displays how the answer is collected',
  QUESTION_INPUT_METHOD_INPUTTED_LABEL: 'Inputted',
  QUESTION_INPUT_METHOD_SELECTED_LABEL: 'Selected',
  QUESTION_INPUT_METHOD_DATE_PICKER_LABEL: 'Date Picker',
  QUESTION_UNITS_LABEL: 'Unit',
  QUESTION_UNITS_HINT:
    'If answer is collecting measurable and quantified input ',
  QUESTION_ALLOW_MULTIPLE_LABEL: 'Allow Multiple',
  QUESTION_ALLOW_MULTIPLE_HINT:
    'Indicates if the question can have multiple responses',
  ANSWER_OPTION_LABEL_ADD: 'Add Answer Option',
  QUESTION_ANSWER_OPTIONS_LABEL: 'Answer Options',
  QUESTION_ANSWER_OPTION_LABEL: 'Answer Option',
  QUESTION_ANSWER_OPTION_TYPE_LABEL: 'Type',
  QUESTION_ANSWER_OPTION_TYPE_HINT: 'Displays type of Answer Option',
  QUESTION_ANSWER_OPTION_TYPE_DATE: 'date',
  QUESTION_ANSWER_OPTION_TYPE_CODING: 'coding',
  QUESTION_ANSWER_OPTION_DISPLAY_TEXT_LABEL: 'Display Text',
  QUESTION_ANSWER_OPTION_VALUE_LABEL: 'Value',
  QUESTION_ANSWER_OPTION_CODE_LABEL: 'Code',
  ANSWER_OPTION_BINDING_LABEL: 'Answer Option Binding',
  ANSWER_OPTION_BINDING_HINT:
    'Specifies if answer options are restricted to a set list',
  ANSWER_OPTION_BINDING_NORMATIVE: 'normative',
  ANSWER_OPTION_BINDING_SELECT_FROM: 'select from',
  QUESTION_REQUIRED_LABEL: 'Required',
  QUESTION_REQUIRED_HINT: 'Indicates if the question is mandatory',
  QUESTION_QUESTION_CODES_ITEM: 'Question Code',
  QUESTION_QUESTION_CODES_ADD_LABEL: 'Add Question Code',
  QUESTION_QUESTION_UNITS_ADD_LABEL: 'Add Unit',
  QUESTION_QUESTION_UNITS_ITEM: 'Unit',
  QUESTION_ANSWER_OPTIONS_ITEM: 'Answer Options Code',
};
