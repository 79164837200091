import * as React from 'react';
import type {
  Entity,
  EntityDetail,
  EntityDetailSchema,
  EnvironmentKey,
} from '@web-config-app/core';
import { getEntityInstanceName, EntityStatusValue } from '@web-config-app/core';
import {
  useEntityOperations,
  useEntityData,
  useEntityStatus,
  useSetEntityData,
  useResetEntityData,
  useIncludedEntities,
  useAddIncludedEntity,
  useSetIncludedEntities,
} from '@web-config-app/core-react';
import type {
  UseEntityOperationsProps,
  UseMultiEnvironmentEntityOperationsProps,
} from '@web-config-app/core-react';
import {
  EntityDetailsProvider,
  createDefaultValueForSchema,
  useSyncDataSources,
} from '@web-config-app/entity-form';
import {
  applyDefaultSchemaAnnotations,
  computeSchema,
  applyConditionalLogic,
} from '@web-config-app/schema-utils';
import { EntityDetailsMultiEnvironmentContainer } from '../entity-details-multi-environment-container/entity-details-multi-environment.container';

export interface EntityDetailsPageContainerProps {
  entity: Entity;
  // TODO:: Fix inconsistent typing of instanceId since it can be undefined
  // https://everlong.atlassian.net/browse/CACT-1600
  instanceId: string;
  currentAuthoringEnvironment: EnvironmentKey;
  path: string;
  options?: UseEntityOperationsProps['options'];
  multiEnvironmentOptions?: UseMultiEnvironmentEntityOperationsProps['options'];
  createDefaultEntityData?: boolean;
  otherAuthoringEnvironment?: EnvironmentKey;
}

export const EntityDetailsPageContainer = ({
  entity,
  instanceId,
  currentAuthoringEnvironment,
  options,
  multiEnvironmentOptions,
  path,
  children,
  createDefaultEntityData,
  otherAuthoringEnvironment,
}: React.PropsWithChildren<EntityDetailsPageContainerProps>) => {
  /**
   * The base schema is the schema as it is stored in the entity config without
   * any conditional logic applied.
   */
  const baseSchema = React.useMemo(
    () => applyDefaultSchemaAnnotations(entity.schema),
    [entity.schema],
  );
  const defaultEntityData = React.useMemo(
    () =>
      createDefaultEntityData
        ? createDefaultValueForSchema<EntityDetail>(baseSchema)
        : undefined,
    [baseSchema, createDefaultEntityData],
  );

  const entityRootData = useEntityData();
  const setEntityData = useSetEntityData();
  const resetEntityData = useResetEntityData();
  const includedEntities = useIncludedEntities();
  const setIncludedEntities = useSetIncludedEntities();
  const addIncludedEntity = useAddIncludedEntity();
  const name = getEntityInstanceName(entityRootData, entity);
  const status = useEntityStatus() ?? EntityStatusValue.New;

  /**
   * We apply conditional rendering to the baseSchema using the current
   * entity data which will return either the full baseSchema or a schema
   * with some properties removed in the event that the conditions for
   * including them are not met
   */

  const rootSchema = React.useMemo(() => {
    const computedAttributesSchema = computeSchema(
      baseSchema.properties.attributes,
      entityRootData?.attributes,
      [applyConditionalLogic],
      { recursive: false },
    );

    return {
      ...baseSchema,
      properties: {
        ...baseSchema.properties,
        attributes: computedAttributesSchema,
      },
    } as EntityDetailSchema;
  }, [baseSchema, entityRootData]);

  useSyncDataSources(rootSchema, entityRootData ?? {}, includedEntities);

  const operations = useEntityOperations({
    entity,
    instanceId,
    options,
    entityRootData: entityRootData ?? {},
    onEntityGetSuccess: (data, includedEntitiesData) => {
      setEntityData(data);
      setIncludedEntities(includedEntitiesData);
    },
    environment: currentAuthoringEnvironment,
  });

  React.useEffect(() => {
    if (createDefaultEntityData && defaultEntityData) {
      setEntityData(defaultEntityData);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return otherAuthoringEnvironment ? (
    <EntityDetailsMultiEnvironmentContainer
      name={name}
      status={status}
      entity={entity}
      instanceId={instanceId}
      multiEnvironmentOptions={multiEnvironmentOptions}
      entityRootData={entityRootData}
      setEntityData={setEntityData}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      otherAuthoringEnvironment={otherAuthoringEnvironment}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      includedEntities={includedEntities}
      setIncludedEntities={setIncludedEntities}
      addIncludedEntity={addIncludedEntity}
      resetEntityData={resetEntityData}
    >
      {children}
    </EntityDetailsMultiEnvironmentContainer>
  ) : (
    <EntityDetailsProvider
      name={name}
      status={status}
      entityRootData={entityRootData}
      setEntityData={setEntityData}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      instanceId={instanceId}
      entityType={entity.type}
      entity={entity}
      includedEntities={includedEntities}
      setIncludedEntities={setIncludedEntities}
      addIncludedEntity={addIncludedEntity}
      resetEntityData={resetEntityData}
    >
      {children}
    </EntityDetailsProvider>
  );
};
