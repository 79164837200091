import { LeagueConfig, isNodeTestEnv } from '@leagueplatform/core';
import { leagueTheme } from '../theme/league';
import { EN_STRING_OVERRIDES } from '../locales/en';
import { MOCK_EN_STRINGS } from './mock-strings';

const MOCK_CONFIG_EN_STRING_OVERRIDES = {
  ...EN_STRING_OVERRIDES,
  ...MOCK_EN_STRINGS,
};

export const mockRulesTemplates = {
  mock_activities_rules_template: {
    id: 'mock_activities_rules_template',
    name: 'Activities Rules Template',
    label: 'MOCK_ACTIVITIES_RULES_TEMPLATE',
    rules: {},
    type: 'completionCriteria',
    entityType: 'config-activity',
  },
  mock_campaigns_rules_template: {
    id: 'mock_campaigns_rules_template',
    name: 'Campaigns Rules Template',
    label: 'MOCK_CAMPAIGNS_RULES_TEMPLATE',
    rules: {},
    type: 'eligibilityCriteria',
    entityType: 'config-campaign',
  },
  mock_cactuses_rules_template: {
    id: 'mock_cactuses_rules_template',
    name: 'Cactuses Rules Template',
    label: 'MOCK_CACTUSES_RULES_TEMPLATE',
    rules: {},
    type: 'completionCriteria',
    entityType: 'config-cactus',
  },
  mock_owls_rules_template: {
    id: 'mock_owls_rules_template',
    name: 'Owls Rules Template',
    label: 'MOCK_OWLS_RULES_TEMPLATE',
    rules: {},
    type: 'eligibilityCriteria',
    entityType: 'config-pygmy-owl',
  },
};

export const mockConfig: LeagueConfig = {
  core: {
    clientId: 'test-app-web',
    contentUrl: 'https://contenturl.test.example.com',
    api: {
      url: 'https://apiurl.test.example.com',
      wsUrl: 'wss://wsurl.test.example.com',
      legacyRestApi: 'https://rest.test.example.com',
    },
    appEnvironment: 'development',

    /**
     * auth is required at the type level for the sake of consumers,
     * but we don't need to initialize it for tests.
     */
    // @ts-ignore
    auth: undefined,
    customMethods: {
      handleLink: async () => {},
    },
    ui: {
      theme: leagueTheme,
    },
    i18n: {
      stringOverrides: {
        default: MOCK_CONFIG_EN_STRING_OVERRIDES,
        en: MOCK_CONFIG_EN_STRING_OVERRIDES,
      },
    },
  },
  configApp: {
    tenantName: 'Oh hi Mark',
    tenantLogo: '',
    authoring: {
      baseEnvironment: {
        apiUrl: 'https://api.current.league.dev',
        name: 'Base Environment',
        id: 'ca:staging',
        environmentKey: 'staging',
        audience: 'https://base-audience.league.dev',
      },
      previewEnvironments: [
        {
          apiUrl: 'https://api.preview.league.dev',
          name: 'Preview Environment',
          type: 'preview',
          audience: 'https://preview-audience.league.dev',
          id: 'ca:uat',
          environmentKey: 'test',
        },
      ],
      disableMultiEnvironmentOperations: false,
    },
    domains: [
      {
        id: 'journey',
        name: 'Journey',
        icon: 'domainJourney',
        category: 'default',
        entities: [
          {
            id: 'activities',
            name: 'Activities',
            nameTranslationKey: 'ACTIVITIES',
            type: 'config-activity',
            category: 'default',
          },
          {
            name: 'Campaigns',
            id: 'campaigns',
            nameTranslationKey: 'CAMPAIGNS',
            type: 'config-campaign',
            category: 'default',
            singleEnvironment: 'base',
          },
          {
            name: 'Hidden entity',
            id: 'hidden-entity',
            nameTranslationKey: 'HIDDEN_ENTITY',
            type: 'config-hidden',
            category: 'hidden',
          },
        ],
      },
      {
        id: 'masonry',
        name: 'Masonry',
        icon: 'illustrativeCompose',
        category: 'default',
        entities: [
          {
            name: 'Containers',
            id: 'containers',
            nameTranslationKey: 'CONTAINERS',
            category: 'default',
          },
          {
            name: 'Widgets',
            id: 'widgets',
            nameTranslationKey: 'WIDGETS',
            category: 'default',
          },
        ],
      },
      {
        id: 'management',
        name: 'Management',
        icon: 'illustrativeCompose',
        category: 'hidden',
        entities: [
          {
            name: 'Assets',
            id: 'assets',
            type: 'config-asset',
            nameTranslationKey: 'ASSETS',
            category: 'hidden',
            endpoints: {
              create: '/create',
              get: {
                path: '/get-config-asset/{assetId}',
                pathParameters: [
                  {
                    name: 'assetId',
                    schema: { type: 'string' },
                    in: 'path',
                  },
                ],
              },
            },
          },
        ],
      },
    ],
    rulesTemplates: mockRulesTemplates,
  },

  __internal: { isNodeTestEnv: isNodeTestEnv() },
};
