import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  WorkflowActionModal,
  CustomWorkflowActionMenuItem,
  ActionMenuItem,
} from '@web-config-app/core';
import type { EntityWorkflowActionsProps } from '../../types/hooks';

export const useMenuItems = ({
  operations,
  status,
  entity,
  isEntitySaved,
  entityConfigName,
  entityListPath,
  environmentName,
  resetEntityData,
}: EntityWorkflowActionsProps): ActionMenuItem[] => {
  const { formatMessage } = useIntl();
  const customActionMenuItems: CustomWorkflowActionMenuItem[] = useMemo(
    () =>
      Object.entries(entity.endpoints.custom ?? {}).map(
        ([actionId, operation]) => ({
          type: 'custom',
          id: actionId,
          operation,
          label:
            operation.label.text ?? formatMessage({ id: operation.label.key }),
          display: true,
        }),
      ),
    [entity.endpoints.custom, formatMessage],
  );

  const menuItems: ActionMenuItem[] = useMemo(() => {
    switch (status) {
      case 'draft':
        return [
          {
            type: 'duplicateAsDraft',
            label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
            tooltip: formatMessage(
              { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
              { entity: entityConfigName },
            ),
            disabled: !isEntitySaved,
            display: Boolean(operations?.duplicateAsDraft.enableAction),
            // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
            action: isEntitySaved ? operations?.duplicateAsDraft.mutate : null,
          },
          {
            type: 'archive',
            label: formatMessage({ id: 'ARCHIVE' }),
            tooltip: formatMessage(
              { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
              { entity: entityConfigName },
            ),
            disabled: false,
            display: Boolean(operations?.archive?.enableAction),
            action: operations?.archive?.mutate,
          },
          ...customActionMenuItems,
        ];
      case 'published':
        return [
          {
            type: 'unpublish',
            label: formatMessage({ id: 'UNPUBLISH' }),
            disabled: false,
            display: Boolean(operations?.unpublish.enableAction),
            modal: {
              type: WorkflowActionModal.confirmation,
              confirmCtaAction: operations?.unpublish?.mutate,
              title: formatMessage(
                { id: 'UNPUBLISH_MODAL_TITLE' },
                { entity: entityConfigName, env: environmentName },
              ),
              description: formatMessage(
                {
                  id: 'UNPUBLISH_MODAL_DESCRIPTION',
                },
                { entity: entityConfigName },
              ),
              confirmCtaText: formatMessage(
                {
                  id: 'UNPUBLISH_PRIMARY_CTA',
                },
                { env: environmentName },
              ),
            },
          },
          {
            type: 'duplicateAsDraft',
            label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
            tooltip: formatMessage(
              { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
              { entity: entityConfigName },
            ),
            disabled: !isEntitySaved,
            display: Boolean(operations?.duplicateAsDraft.enableAction),
            // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
            action: isEntitySaved ? operations?.duplicateAsDraft.mutate : null,
          },
          {
            type: 'archive',
            label: formatMessage({ id: 'ARCHIVE' }),
            tooltip: formatMessage(
              { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
              { entity: entityConfigName },
            ),
            disabled: true,
            display: Boolean(operations?.archive?.enableAction),
          },
          ...customActionMenuItems,
        ];
      case 'archived':
        return [];
      case 'new':
      default:
        return [
          {
            type: 'exitAndDiscardChanges',
            label: formatMessage({ id: 'EXIT_AND_DISCARD_CHANGES' }),
            disabled: false,
            display: !isEntitySaved,
            modal: {
              type: WorkflowActionModal.confirmation,
              confirmCtaAction: entityListPath,
              confirmCtaOnclick: resetEntityData,
              title: formatMessage(
                { id: 'EXIT_ENTITY_DETAIL_TITLE' },
                { entity: entityConfigName },
              ),
              description: formatMessage({
                id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
              }),
              confirmCtaText: formatMessage({
                id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
              }),
            },
          },
        ];
    }
  }, [
    entityConfigName,
    formatMessage,
    isEntitySaved,
    operations?.archive?.enableAction,
    operations?.archive?.mutate,
    operations?.duplicateAsDraft?.enableAction,
    operations?.duplicateAsDraft?.mutate,
    operations?.unpublish?.enableAction,
    operations?.unpublish?.mutate,
    status,
    entityListPath,
    customActionMenuItems,
    resetEntityData,
    environmentName,
  ]);

  return useMemo(() => menuItems.filter((item) => item.display), [menuItems]);
};
