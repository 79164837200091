import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  EntityDetailsHeader,
  EntityActionMenuItem,
} from '@web-config-app/core-react-ui';
import {
  useEntityDetailsProps,
  useEntityWorkflowActions,
  useEntityHeaderSaveStatus,
} from '@web-config-app/entity-form';
import type {
  WorkflowActionMenuItem,
  CustomWorkflowAction,
  EnvironmentKey,
  Entity,
} from '@web-config-app/core';
import {
  getEntityStatusBadgeProps,
  EntityDetailActionType,
  EntityStatusValue,
} from '@web-config-app/core';
import { useEntityDetailsEnvironmentSelect } from '@web-config-app/core-react';
import type { GetEntityDetailsPathForEnvAndId } from '@web-config-app/core-react';
import { CustomEntityActionMenuItem } from '../custom-entity-action-menu-item/custom-entity-action-menu-item.component';

interface EntityDetailsHeaderContainerProps {
  isLoading: boolean;
  entity: Entity;
  entityListPath: string;
  environmentKey: EnvironmentKey;
  environmentName: string;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  setEntityName: (name: string) => void;
  publishDangerously: boolean;
}

const isCustomAction = (
  item?: WorkflowActionMenuItem | CustomWorkflowAction,
): item is CustomWorkflowAction =>
  typeof item !== 'undefined' && item.type === 'custom';

export const EntityDetailsHeaderContainer = ({
  isLoading = false,
  entity,
  entityListPath,
  environmentKey,
  getEntityDetailsPathForEnvAndId,
  setEntityName,
  publishDangerously,
  environmentName,
}: EntityDetailsHeaderContainerProps) => {
  const { formatMessage } = useIntl();
  const { nameTranslationKey, schema } = entity;
  const entityConfigName = formatMessage(
    { id: nameTranslationKey },
    { count: 1 },
  );

  const {
    operations,
    name,
    status,
    instanceId,
    entityRootData,
    otherAuthoringEnvironmentState,
    isReadOnly,
    resetEntityData,
  } = useEntityDetailsProps();

  const { isEntitySaved, message: saveStatusMessage } =
    useEntityHeaderSaveStatus({
      operations,
      entityRootData,
      status,
    });

  const {
    currentAuthoringEnvironment,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
    multiEnvironmentActions,
    isEntitySingleEnv,
  } = useEntityDetailsEnvironmentSelect({
    status,
    entityName: entityConfigName,
    getEntityDetailsPathForEnvAndId,
    environmentKey,
    entityInstanceId: instanceId,
    otherAuthoringEnvironmentState,
    isEntitySaved,
    entity,
  });

  const {
    primaryAction,
    secondaryAction,
    menuItems: workflowMenuItems = [],
  } = useEntityWorkflowActions({
    operations,
    entity,
    status,
    isEntitySaved,
    entityConfigName,
    entityListPath,
    environmentName,
    publishDangerously,
    isReadOnly,
    resetEntityData,
  });

  /**
   * Since we can't handle custom actions the same way as predefined actions
   * (which are passed, via `useEntityOperations`, as `operations`) and incorporated
   * into `workflowMenuItems`, we need come up with a way to associate the clicked
   * menu item with its corresponding action.
   *
   * This should be rectified in https://everlong.atlassian.net/browse/CACT-1844
   */
  const [customActionsMap, setCustomActionsMap] = React.useState<
    Record<string, () => void>
  >({});

  const registerCustomMenuAction = React.useCallback(
    (itemId: string, action: () => void) => {
      if (customActionsMap[itemId] !== action) {
        setCustomActionsMap({
          ...customActionsMap,
          [itemId]: action,
        });
      }
    },
    [customActionsMap],
  );

  const statusBadgeProps = getEntityStatusBadgeProps(status);

  const statusBadge = {
    label: formatMessage({ id: statusBadgeProps?.labelKey }),
    status: statusBadgeProps?.genesisStatus,
  };

  const isArchived = status === EntityStatusValue.Archived;

  const menuItems = workflowMenuItems.map((item) =>
    isCustomAction(item)
      ? {
          id: item.id,
          label: (
            <CustomEntityActionMenuItem
              entityName={entityConfigName}
              item={item}
              entity={entity}
              instanceId={instanceId}
              environment={environmentKey}
              data={entityRootData}
              registerCustomMenuAction={registerCustomMenuAction}
              options={operations?.custom?.options}
            />
          ),
        }
      : {
          id: item.type,
          label: <EntityActionMenuItem item={item} />,
        },
  );

  const onMenuItemClicked = React.useCallback(
    (itemId: EntityDetailActionType | string) => {
      const menuItem = workflowMenuItems.find((item) =>
        isCustomAction(item) ? item.id === itemId : item.type === itemId,
      );
      if (isCustomAction(menuItem) && itemId in customActionsMap) {
        customActionsMap[itemId]();
      } else {
        menuItem?.action?.();
      }
    },
    [workflowMenuItems, customActionsMap],
  );

  const menuItemGroup = { menuItems, onMenuItemClicked };

  const entityInstanceName =
    name || `${formatMessage({ id: 'UNTITLED' })} ${entityConfigName}`;

  /**
   * Check if the entity adds a custom path to the entity name or whether
   * it uses the {@link EntityMetadata} interface.
   */
  const isNameInEntityMetadata = !schema['x-entity-metadata']?.entityNamePath;

  return (
    <EntityDetailsHeader
      isLoading={isLoading}
      exitModalPrimaryButtonPath={entityListPath}
      menuItemGroup={menuItemGroup}
      secondaryAction={secondaryAction}
      primaryAction={primaryAction}
      statusBadge={statusBadge}
      entityInstanceName={entityInstanceName}
      isEntitySaved={isEntitySaved}
      saveStatusMessage={saveStatusMessage}
      isArchived={isArchived}
      entityConfigName={entityConfigName}
      instanceId={instanceId}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      availableEnvironments={availableEnvironments}
      multiEnvironmentActions={multiEnvironmentActions}
      isNameEditable={isNameInEntityMetadata}
      setEntityName={setEntityName}
      multiEnvironmentAuthoringEnabled={multiEnvironmentAuthoringEnabled}
      resetEntityData={resetEntityData}
      isEntitySingleEnv={isEntitySingleEnv}
    />
  );
};
