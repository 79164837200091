import * as React from 'react';
import type {
  Nullable,
  EntityRootData,
  EntityDetail,
  EnvironmentKey,
  Entity,
} from '@web-config-app/core';
import type {
  OtherAuthoringEnvironmentState,
  UseEntityOperationsResult,
} from '@web-config-app/core-react';
import {
  useEntityDetails,
  UseEntityDetailsProps,
  UseEntityDetailsResult,
} from '../../hooks/use-entity-details/use-entity-details';

export type EntityDetailsContextType = UseEntityDetailsResult & {
  operations: UseEntityOperationsResult;
  currentAuthoringEnvironment: EnvironmentKey;
  otherAuthoringEnvironmentState?: OtherAuthoringEnvironmentState;
  entityRootData: EntityRootData | undefined;
  setEntityData: (data: any, path: string) => void;
  onTreeNodeSelect?: (args: any) => void;
  entityType: string;
  includedEntities: EntityDetail[] | undefined;
  addIncludedEntity?: (entity: EntityDetail) => void;
  setIncludedEntities?: (entities: EntityDetail[]) => void;
  resetEntityData: () => void;
  entity: Entity;
};

export type EntityDetailsContextResult = Nullable<EntityDetailsContextType>;

export const EntityDetailsContext =
  React.createContext<EntityDetailsContextResult>(null);

export interface Included {}

export interface EntityDetailsProviderProps
  extends Omit<UseEntityDetailsProps, 'data' | 'onDataChange'> {
  operations: UseEntityOperationsResult;
  currentAuthoringEnvironment: EnvironmentKey;
  otherAuthoringEnvironmentState?: OtherAuthoringEnvironmentState;
  entityType: string;
  includedEntities: EntityDetail[] | undefined;
  setIncludedEntities: (data: EntityDetail[] | undefined) => void;
  addIncludedEntity: (data: EntityDetail) => void;
  resetEntityData: () => void;
}

export const EntityDetailsProvider = ({
  children,
  operations,
  entityRootData,
  currentAuthoringEnvironment,
  otherAuthoringEnvironmentState,
  rootSchema,
  onTreeNodeSelect,
  setEntityData,
  path,
  entityType,
  instanceId,
  entity,
  includedEntities,
  setIncludedEntities,
  addIncludedEntity,
  resetEntityData,
  name,
  status,
}: React.PropsWithChildren<EntityDetailsProviderProps>) => {
  const entityDetailsValue = useEntityDetails({
    name,
    status,
    path,
    instanceId,
    rootSchema,
    entityRootData,
    onTreeNodeSelect,
    setEntityData,
    entity,
  });

  const entityDetailsCtxValue = React.useMemo(
    () => ({
      ...entityDetailsValue,
      includedEntities,
      setIncludedEntities,
      addIncludedEntity,
      resetEntityData,
      entityType,
      operations,
      currentAuthoringEnvironment,
      otherAuthoringEnvironmentState,
      entityRootData,
      entity,
    }),
    [
      entityDetailsValue,
      entityType,
      operations,
      currentAuthoringEnvironment,
      otherAuthoringEnvironmentState,
      entityRootData,
      includedEntities,
      setIncludedEntities,
      addIncludedEntity,
      resetEntityData,
      entity,
    ],
  );

  return (
    <EntityDetailsContext.Provider value={entityDetailsCtxValue}>
      {children}
    </EntityDetailsContext.Provider>
  );
};
