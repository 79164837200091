export enum AppTenant {
  Example = 'example',
  B2B = 'b2b',
  PCHealth = 'pchealth',
  HighmarkIel = 'highmark-iel',
  HighmarkBeneficity = 'highmark-beneficity',
  HighmarkAllMyHealth = 'highmark-allmyhealth',
  HighmarkEnGen = 'highmark-engen',
  Optum = 'optum',
  BCBSAZ = 'bcbsaz',
  Manulife = 'manulife',
  OvatientMetroHealth = 'ovatient-metrohealth',
  XOHealth = 'xohealth',
  Cigna = 'cigna',
  Premera = 'premera',
  SCAN = 'scan',
  SCFHP = 'scfhp',
  Baptist = 'baptist',
  Telus = 'telus',
  Reference = 'reference',
  MedibankValencia = 'medibank-valencia',
  TelusABHealth = `telus-abhealth`,
}

export type TenantId = `${AppTenant}`;

export type ViteAppEnvironment = 'staging' | 'production';
